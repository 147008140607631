import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ReactSession }  from 'react-client-session';
import { useNavigate } from 'react-router-dom';
import numeral from 'numeral';
import SideBar from './sidebar';
import EsideBar from './esidebar';
import NavBar from './navbar';
import Footer from './footer';
import Popup from 'reactjs-popup';
import './test.css';
function App ()  {

  const userId = ReactSession.get("userId");
  const userType = ReactSession.get("userType");
  console.log(userId);
  const navigate = useNavigate();
  const indexPath = '/';
  
  useEffect(() => {
    if (userId == 0 ) {
      navigate(indexPath);
    }
    
  }, [userId, navigate]);


  const [orderData, setOrderData] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;
        if (userType == 1) {
          response = await axios.get('https://projects.sninfoserv.com/007-api/dine/orders/');
        } else if (userType == 2) {
          response = await axios.post('https://projects.sninfoserv.com/007-api/dine/orders/', { "userId":userId });
        }
        setOrderData(response.data);
         console.log(response.data);
      } catch (error) {
        // Handle error if needed
      }
    };
  
    fetchData();
  
    const intervalId = setInterval(() => {
      fetchData();
    }, 1000);
  
    return () => clearInterval(intervalId);
  }, [userType, userId]); // Add dependencies that are used inside the effect
  
  // Render your component based on the orderData state
  
  


    const popupStyle = {
        content: {
          margin: 'auto',
          background: 'rgb(255, 255, 255)',
          width: '20%',
          padding: '5px',
        },
        arrow: {
          color: '#6c757d',
        },
        tooltip: {
          width: '200px',
          boxShadow: '#333',
        },
        overlay: {
          background: 'rgba(0, 0, 0, 0.5)',
        },
      };

      const handleClick = async (id) => {
       // console.log(`Button with id ${id} clicked`);
        const orderId = {"order_id": id};
      
        try {
          const response = await axios.post('https://projects.sninfoserv.com/007-api/dine/update/', orderId);
      
          if (response.data.status === 'success') {
            console.log('Status Updated!');
          }else{
            console.log(response.error.message);
          }
      
        } catch (error) {
          console.error('Update failed!', error);
        }
      };

       // Function to handle printing
       const handlePrint = (content) => {
        const iframe = document.createElement('iframe');
        iframe.setAttribute('style', 'display: none;');
        document.body.appendChild(iframe);
        const iframeDoc = iframe.contentWindow.document;
        iframeDoc.open();
        iframeDoc.write(content);
        iframeDoc.close();
        iframe.contentWindow.print();
      };
  
  // Function to generate HTML content for the table
  const generateTableContent = (order) => {
    const total = order.order_amount;
    const cgst = total * (2.5 / 100);
    const sgst = total * (2.5 / 100);
    const gTotal = parseFloat(total) + parseFloat(cgst) + parseFloat(sgst);
    return `
      <html>
      <head>
        <title>Order Details</title>
        <style>
          body {
            margin: 0px; 
          }
          .header {
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            margin-bottom: 5px; 
          }
          .address {
            font-size: 12px;
            font-weight: bold;
            text-align: center;
            margin-bottom: 0px; 
          }
          .address1 {
            font-size: 12px;
            font-weight: bold;
            text-align: left;
            margin-bottom: 0px; 
          }
          .address2 {
            font-size: 12px;
            font-weight: bold;
            text-align: right;
            margin-bottom: 0px; 
          }
          .content {
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 20px;
          }
          .left {
            width: 70%;
            text-align: left;
          }
          .middle {
            width: 10%;
            text-align: center;
          }
          .right {
            width: 20%;
            text-align: right;
          }
        </style>
      </head>
      <body>
        <div class="header">Resto Control</div>
        <div class="address">No.3, Nagappa Coplex, RS Puram, Coimbatore - 641002</div>
        <div class="address">GSTIN : ABCDE123456FG</div><br>
        <div class="address1">Bill No : #${order.order_id}</div>
        <table align="center">
            ${order.order_item_details.map((orderDetails) =>`
              <tr>
                <td class="content left">${orderDetails.order_item}</td>
                <td class="content middle">${orderDetails.order_qty}</td>
                <td class="content right">₹${numeral(orderDetails.order_price * orderDetails.order_qty).format('0,0.00')}</td>
              </tr>`
            )}
        </table>
        <br>
        <div class="address2">Net Total : ₹${numeral(total).format('0,0.00')}</div>
        <div class="address2">CGST (2.5%) : ₹${numeral(cgst).format('0,0.00')}</div>
        <div class="address2">SGST (2.5%) : ₹${numeral(sgst).format('0,0.00')}</div>
        <div class="address2"><b style="font-size:14px">Grand Total : ₹${numeral(gTotal).format('0,0.00')}</b></div><br>
         <div class="address1">${order.order_name}</div>
         <div class="address1">${order.order_phone}</div>
         <div class="address1">${order.order_address}</div>
      </body>
      </html>
    `;
  };
  

  return (
    

    <div  id="mainDiv">
    { userType === '1' ?(
    <SideBar />
      ) : (< EsideBar />
     )}

    <main class="main-content position-relative border-radius-lg hidden-for-print1">
    <div class="min-height-300 bg-primary position-absolute w-100"></div>
    <NavBar />
   
    <div class="container-fluid py-4">
    <div class="row">
        <div class="col-12">
         
            <div class="card-header pb-0">
              <h6>Dine In</h6>
            </div>
            <div class="card-body px-1 pt-1 pb-2">
              <div class="row">
              {orderData && orderData.data && orderData.data.map(orders => (

                <div class="col-lg-3 col-md-12 col-12 mb-4">
                <div class="card">
                  <div class="card-body">
                    <div class="card-title d-flex align-items-start justify-content-between">
                      <h6>{orders.order_area}</h6>
                      <Popup trigger={<button class="btn btn-primary btn-sm ms-auto">Items - {orders.order_items}</button>} >
                         <div class="col-lg-12">
                          <div class="card h-100">
                           <div class="card-header pb-0 p-3">
                            <div class="row">
                             <div class="col-12 d-flex align-items-center">
                              <h6 class="mb-0 text-primary">Order Details</h6>
                             </div>
                             
                            </div>
                           </div>
                           <div class="card-body p-3 pb-0">
                            <ul class="list-group">

                            {orders.order_item_details.map(orderDetails => (

                             <li class="border-0 d-flex justify-content-between ps-0 mb-0 border-radius-lg">
                              <div class="d-flex flex-column">
                               <h6 class="mb-1 text-dark font-weight-bold text-sm py-2">{orderDetails.order_item}  <span class="text-secondary"> x {orderDetails.order_qty}</span></h6>
                              </div>
                              
                              
                             </li>

                             ))}
               
                            </ul>
                           </div>
                          </div>
                         </div>
                       </Popup>
                    </div>  
                    <span class="fw-semibold d-block mb-1">{orders.order_table}</span>
                    <h3 class="card-title mb-2">₹ {numeral(orders.order_amount).format('0,0.00')}</h3>
                    <small class="text-success fw-semibold"><i class="bx bx-up-arrow-alt"></i>{orders.order_s_time} ( {orders.order_timing} )</small>
                    <div class="mt-2 text-align-center" align="center">
                    {orders.order_status === 'Started' ? (
                         <button className="btn btn-warning btn-sm ms-auto" onClick={() => handleClick(orders.order_id)}>
                           {orders.order_status}
                         </button>
                       ) : (
                         <button className="btn btn-success btn-sm ms-auto" onClick={() => handlePrint(generateTableContent(orders))}>
                           Print Bill
                         </button>
                      )}
                      </div>
                  </div>
                  
                </div>
              </div>
                    
                 ))}
              </div>
            </div>
          </div>
        </div>
      <Footer />
    </div>
  </main>

  </div>

  
  )
}
export default App;