import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SideBar from './sidebar';
import NavBar from './navbar';
import Footer from './footer';
import logo from '../assets/img//logos/logo_007.png';

function App() {
  

  const isPowerOn = false;

  const [hutData, setData] = useState(true);
 
    const fetchHutData = async () => {
      try {
        const response = await axios.get('https://projects.sninfoserv.com/007-api/dine/hut/');
        setData(response.data);
        //console.log(response.data);
      } catch (error) {
      } 
    };

  useEffect(() => {
    fetchHutData();
  }, []);


  const [rfTableData, setrfTableData] = useState(true);
    const fetchrfTableData = async () => {
      try {
        const response = await axios.get('https://projects.sninfoserv.com/007-api/dine/roof_top/');
        setrfTableData(response.data);
        //console.log(response.data);
      } catch (error) {
      } 
    };

  ;

  useEffect(() => {
    fetchrfTableData();
  }, []);

  const [outerData, setouterData] = useState(true);
 
    const fetchOuterData = async () => {
      try {
        const response = await axios.get('https://projects.sninfoserv.com/007-api/dine/outer/');
        setouterData(response.data);
        //console.log(response.data);
      } catch (error) {
      } 
    };

  useEffect(() => {
    fetchOuterData();
  }, []);
  

  const [acHallData, setacHallData] = useState(true);
    const fetchAcHallData = async () => {
      try {
        const response = await axios.get('https://projects.sninfoserv.com/007-api/dine/ac_hall/');
        setacHallData(response.data);
        //console.log(response.data);
      } catch (error) {
      } 
    };

  useEffect(() => {
    fetchAcHallData();
  }, []);

  const togglePowers = {

  };

  const [PowerData, setPowerData] = useState(true);
  const Power = async (area, section) => {
    try {
      const response =  await axios.post('https://projects.sninfoserv.com/007-api/manage/table/', { section: section, area: area });
      setPowerData(response.data);
      console.log(response.data);
      fetchHutData();
      fetchrfTableData();
      fetchOuterData();
      fetchAcHallData();
    } catch (error) {
      console.error('Error posting hut_id:', error);
      
    }
  };



  const [AddData, setAddData] = useState(true);
  const  Add_section = async (area) => {
    try {
      const response =  await axios.post('https://projects.sninfoserv.com/007-api/manage/area/', { area: area });
      setAddData(response.data);
      fetchHutData();
      fetchrfTableData();
      fetchOuterData();
      fetchAcHallData();
    } catch (error) {
      console.error('Error posting hut_id:', error);
      
    }
  };
  
  const [DeleteData, setDeleteData] = useState(true);
  const Delete = async (area, section) => {
    try {
      const response =  await axios.post('https://projects.sninfoserv.com/007-api/manage/delete/', { section: section, area: area });
      setDeleteData(response.data);
      console.log(response.data);
      fetchHutData();
      fetchrfTableData();
      fetchOuterData();
      fetchAcHallData();
    } catch (error) {
      console.error('Error posting hut_id:', error);
      
    }
  };
    
  return (
    <div class="g-sidenav-show   bg-gray-100">
    <div class="min-height-300 bg-primary position-absolute w-100"></div>
    <SideBar />

    <main class="main-content position-relative border-radius-lg ">
    
    <NavBar />
   
    <div class="container-fluid py-4">
    <div class="row">
        <div class="col-xl-4 col-sm-6 mb-xl-0 mb-4">
          <div class="card">
            <div class="card-body p-4">
              <div class="row mt-4">
                <div class="col-8">
                  <div class="numbers">
                    <p class="text-sm mb-0 text-uppercase font-weight-bold">Restaurant Manage</p>
                 
                    <p class="text-sm mb-0">


                    </p>
                  </div>
                </div>
                <div class="col-4 text-end">
                <button
                 className={`icon icon-shape bg-gradient-${isPowerOn ? 'success' : 'danger'} shadow-${isPowerOn ? 'success' : 'danger'} text-center rounded-circle t-1 ${
                  isPowerOn ? 'font-weight-bolder' : ''
            }`}
            onClick={togglePowers}
            style={{
                border: 'none',
                outline: 'none',
              }}>
            {isPowerOn ?  (
    <i
      className="fa fa-check-circle-o text-center text-lg opacity-10"
      style={{ color: 'white', position: 'relative', top: '1px' }}
    ></i>
  ) : (
    <i
      className="fa fa-power-off text-center text-lg opacity-10"
      style={{ color: 'white', position: 'relative', top: '1px' }}
    ></i>
  )}
            </button>
                </div>
              
              </div>
            </div>
          </div>
        </div>
         

        <div class="col-xl-6 col-sm-6">
          <div class="card">
            <div class="card-body p-4">
              <div class="row">
                <div class="col-8">
                  <div class="numbers">
                    <p class="ftext-sm mb-0 text-uppercase font-weight-bold p-4">
                    007 Punjabi Cafe
                    </p>
                </div>
                </div>
                <div class="col-4 text-end">
            <div class="avatar avatar-xl position-relative">
              <img src={logo} alt="profile_image h-100" class="w-100 border-radius-lg shadow-sm"/>
            </div>
              </div>
            </div>
          </div>
          </div>
        </div>

       
    </div>
     
    <div class="row mt-8">
	  <h6 class="font-weight-bolder">HUT</h6>
       
      {hutData && hutData.data && hutData.data.map(hut => (              

        <div class="col-xl-2 col-sm-3 mb-xl-0 mb-4 mt-4" key={hut.hut_id}>
          <div class="card">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-7">
                  <div class="numbers">
                    <p class="text-sm mb-0 text-uppercase font-weight-bold">{hut.hut_name}</p>
                  </div>
                </div>
                <div class="col-4 text-end">
                <a class="btn btn-link text-danger text-gradient text-sm" href="javascript:;" style={{marginTop: "-10px"}}>
  <i class="far fa-trash-alt text-lg"  onClick={() => Delete('hut', hut.hut_id)}></i></a>
                </div>
                <div class="col-12 text-center">
                <button
                 className={`icon icon-shape bg-gradient-${hut.hut_status == 'yes' ? 'success' : 'danger'} shadow-${hut.hut_status == 'yes' ? 'success' : 'danger'} text-center rounded-circle t-1 ${
                  hut.hut_status == 'yes' ? 'font-weight-bolder' : ''
            }`}
            onClick={() => Power('hut', hut.hut_id)}
            style={{
                border: 'none',
                outline: 'none', 
              }}>
            {hut.hut_status == 'yes' ?  (
    <i
      className="fa fa-check-circle-o text-center text-lg opacity-10"
      style={{ color: 'white', position: 'relative', top: '1px' }}
    ></i>
  ) : (
    <i
      className="fa fa-power-off text-center text-lg opacity-10"
      style={{ color: 'white', position: 'relative', top: '1px' }}
    ></i>
  )}
            </button>
          </div> 
                <p class="mb-0 text-sm mt-2">
                      <span class="text-success text-sm font-weight-bolder" >{hut.hut_date}</span>  
                    </p>
              </div>
            </div>
          </div>
        </div>
    ))}

<div class="col-xl-2 col-sm-3 mb-xl-0 mb-4 mt-4">
  <a>
    <div class="card" onClick={() => Add_section('hut')}>
      <div class="card-body p-5">
        <div class="row">
          <div class="col-12 text-center">
            <div class="icon icon-shape bg-gradient-primary shadow-primary text-center rounded-circle mt-3">
              <i class="fa fa-plus text-center text-lg opacity-10" style={{ color: 'white', position: 'relative', top: '12px' }} aria-hidden="true"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    </a>
  </div>
               
		</div>
		
        
	  
	<div class="row mt-5">
	  <h6 class="font-weight-bolder">ROOF TOP</h6>
      {rfTableData && rfTableData.data && rfTableData.data.map(rfTable => (
    
        <div class="col-xl-2 col-sm-6 mb-xl-0 mb-4 mt-4" key={rfTable.table_id}>
          <div class="card">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-7">
                  <div class="numbers">
                    <p class="text-sm mb-0 text-uppercase font-weight-bold">{rfTable.table_name}</p>
                  </div>
                </div>
                <div class="col-4 text-end">
                <a class="btn btn-link text-danger text-gradient text-sm" href="javascript:;"  style={{marginTop: "-10px"}}>
  <i class="far fa-trash-alt text-lg" onClick={() => Delete('roof_top', rfTable.table_id)}></i></a>
                </div>
                <div class="col-12 text-center">
                <button
                 className={`icon icon-shape bg-gradient-${rfTable.table_status == 'yes' ? 'success' : 'danger'} shadow-${rfTable.table_status == 'yes' ? 'success' : 'danger'} text-center rounded-circle t-1 ${
                  rfTable.table_status == 'yes' ? 'font-weight-bolder' : ''
            }`}
            onClick={() => Power('roof_top', rfTable.table_id)}
            style={{
                border: 'none', // Remove border
                outline: 'none', // Remove outline
                // Add any other styles you need
              }}>
            {rfTable.table_status == 'yes' ? (
    <i
      className="fa fa-check-circle-o text-center text-lg opacity-10"
      style={{ color: 'white', position: 'relative', top: '1px' }}
    ></i>
  ) : (
    <i
      className="fa fa-power-off text-center text-lg opacity-10"
      style={{ color: 'white', position: 'relative', top: '1px' }}
    ></i>
  )}</button>
          </div>  
                <p class="mb-0 text-sm mt-2">
                      <span class="text-success text-sm font-weight-bolder" >{rfTable.table_date}</span>  
                    </p>
              </div>
            </div>
          </div>
        </div>
    ))}
<div class="col-xl-2 col-sm-6 mb-xl-0 mb-4 mt-4">
    <div class="card" onClick={() => Add_section('roof_top')}>
      <div class="card-body p-5">
        <div class="row">
          <div class="col-12 text-center">
            <div class="icon icon-shape bg-gradient-primary shadow-primary text-center rounded-circle mt-2">
            <i class="fa fa-plus text-center text-lg opacity-10" style={{ color: 'white', position: 'relative', top: '12px' }} aria-hidden="true"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
        
    </div>
	  
	<div class="row mt-5">
	  <h6 class="font-weight-bolder">OUTER</h6>
      {outerData && outerData.data && outerData.data.map(outer => (
		
        <div class="col-xl-2 col-sm-6 mb-xl-0 mb-4 mt-4" key={outer.table_id}>
          <div class="card">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-7">
                 <div class="numbers">
                    <p class="text-sm mb-0 text-uppercase font-weight-bold">{outer.table_name}</p>
                 </div>
                </div>
                <div class="col-4 text-end">
                <a class="btn btn-link text-danger text-gradient text-sm" href="javascript:;" style={{marginTop: "-10px"}}>
  <i class="far fa-trash-alt text-lg" onClick={() => Delete('outer_table', outer.table_id)}></i></a>
                </div>
                <div class="col-12 text-center">
                <button
                 className={`icon icon-shape bg-gradient-${outer.table_status == 'yes' ? 'success' : 'danger'} shadow-${outer.table_status == 'yes' ? 'success' : 'danger'} text-center rounded-circle t-1 ${
                  outer.table_status == 'yes' ? 'font-weight-bolder' : ''
            }`}
            onClick={() => Power('outer_table', outer.table_id)}
            style={{
                border: 'none', // Remove border
                outline: 'none', // Remove outline
                // Add any other styles you need
              }} >
            {outer.table_status == 'yes' ? (
    <i
      className="fa fa-check-circle-o text-center text-lg opacity-10"
      style={{ color: 'white', position: 'relative', top: '1px' }}
    ></i>
  ) : (
    <i
      className="fa fa-power-off text-center text-lg opacity-10"
      style={{ color: 'white', position: 'relative', top: '1px' }}
    ></i>
  )}
            </button>
          </div> 
                <p class="mb-0 text-sm mt-2">
                      <span class="text-success text-sm font-weight-bolder" >{outer.table_date}</span>  
                    </p>
              </div>
            </div>
          </div>
        </div>
    ))}

<div class="col-xl-2 col-sm-6 mb-xl-0 mb-4 mt-4">
    <div class="card" onClick={() => Add_section('outer_table')}>
      <div class="card-body p-5">
        <div class="row">
          <div class="col-12 text-center">
            <div class="icon icon-shape bg-gradient-primary shadow-primary text-center rounded-circle mt-2">
            <i class="fa fa-plus text-center text-lg opacity-10" style={{ color: 'white', position: 'relative', top: '12px' }} aria-hidden="true"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
       
    </div>
	  
	<div class="row mt-5">
	  <h6 class="font-weight-bolder">AC HALL</h6>
      {acHallData && acHallData.data && acHallData.data.map(acHall => (

<div class="col-xl-2 col-sm-6 mb-xl-0 mb-4 mt-4" key={acHall.table_id}>
  <div class="card">
    <div class="card-body p-3">
      <div class="row">
        <div class="col-7">
          <div class="numbers">
            <p class="text-sm mb-0 text-uppercase font-weight-bold">{acHall.table_name}</p>
                    
                  </div>
                </div>
                <div class="col-4 text-end">
                <a class="btn btn-link text-danger text-gradient text-sm" href="javascript:;" style={{marginTop: "-10px"}}>
  <i class="far fa-trash-alt text-lg" onClick={() => Delete('ac_hall', acHall.table_id)}></i></a>
                </div>
                <div class="col-12 text-center">
              
                <button
  className={`icon icon-shape bg-gradient-${acHall.table_status == 'yes' ? 'success' : 'danger'} shadow-${acHall.table_status == 'yes' ? 'success' : 'danger'} text-center rounded-circle t-1 ${
    acHall.table_status == 'yes' ? 'font-weight-bolder' : ''
  }`}
  onClick={() => Power('ac_hall', acHall.table_id)}
  style={{
    border: 'none',
    outline: 'none',
    // Add any other styles you need
  }}
>
  {acHall.table_status == 'yes' ? (
    <i
      className="fa fa-check-circle-o text-center text-lg opacity-10"
      style={{ color: 'white', position: 'relative', top: '1px' }}
    ></i>
  ) : (
    <i
      className="fa fa-power-off text-center text-lg opacity-10"
      style={{ color: 'white', position: 'relative', top: '1px' }}
    ></i>
  )}
</button>


          </div> 
                <p class="mb-0 text-sm mt-2">
                      <span class="text-success text-sm font-weight-bolder" >{acHall.table_date}</span>  
                    </p>
              </div>
            </div>
          </div>
        </div>
    ))}

<div class="col-xl-2 col-sm-6 mb-xl-0 mb-4 mt-4">
    <div class="card" onClick={() => Add_section('ac_hall')}>
      <div class="card-body p-5">
        <div class="row">
          <div class="col-12 text-center">
            <div class="icon icon-shape bg-gradient-primary shadow-primary text-center rounded-circle mt-2">
            <i class="fa fa-plus text-center text-lg opacity-10" style={{ color: 'white', position: 'relative', top: '12px' }} aria-hidden="true"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

    

    </div>
      <Footer />
    </div>
  </main>

  </div>
  )
}
export default App;