import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ReactSession }  from 'react-client-session';
import { useNavigate } from 'react-router-dom';
import SideBar from './sidebar';
import EsideBar from './esidebar';
import NavBar from './navbar';
import Footer from './footer';

function App() {

  const userId = ReactSession.get("userId");
  const userType = ReactSession.get("userType");
  const navigate = useNavigate();
  const indexPath = '/';
  
  useEffect(() => {
    if (userId == 0 ) {
      navigate(indexPath);
    }
    
  }, [userId, navigate]);
  
  let employeeId;

if (userType == 1) {
  const queryParameters = new URLSearchParams(window.location.search);
  employeeId = queryParameters.get("id");
} else {
  employeeId = userId;
}

const value = { "employee_id": employeeId };

  const [employeeDetails, setEmployeeData] = useState(true);
  
  const fetchData = async () => {
    try {
      const response = await axios.post('https://projects.sninfoserv.com/007-api/employees/', value);
      setEmployeeData(response.data);
      //console.log(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } 
  };
  
  useEffect(() => {
    fetchData();
  }, []);
    
  const getFileType = (fileUrl) => {
    const extension = fileUrl.split('.').pop().toLowerCase();
    if (extension === 'pdf') {
      return 'pdf';
    } else if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) {
      return 'image';
    } else {
      return 'unknown';
    }
  };
  const empData = employeeDetails?.data || [];
  const fileUrl1 = empData && empData.length > 0 ? `https://projects.sninfoserv.com/007-api/employees/update/uploads/${empData[0].employee_address_proof}` : `../documents/user.webp`;
  const fileUrl2 = empData && empData.length > 0 ? `https://projects.sninfoserv.com/007-api/employees/update/uploads/${empData[0].employee_id_proof}` : `../documents/user.webp`;
  const fileType1 = getFileType(fileUrl1);
  const fileType2 = getFileType(fileUrl2);


  const [name, setName] = useState('Loading');
  const [email, setEmail] = useState('Loading');
  const [phone, setPhone] = useState('Loading');
  const [position, setPosition] = useState('Loading');
  const [photo, setPhoto] = useState('Loading');
  const [adrs_proof, setAdproof] = useState('Loading');
  const [id_proof, setIdproof] = useState('Loading');
  const [state, setState] = useState('Loading');
  const [country, setCountry] = useState('Loading');
  const [address, setAddress] = useState('Loading');
  const [pin, setPin] = useState('Loading');

  useEffect(() => {
    const iName = employeeDetails?.data?.[0]?.employee_name;
    const iEmail = employeeDetails?.data?.[0]?.employee_email;
    const iPhone= employeeDetails?.data?.[0]?.employee_phone;
    const iPosition = employeeDetails?.data?.[0]?.employee_position;
    const iPhoto= employeeDetails?.data?.[0]?.employee_image;
    const iAdrs_proof = employeeDetails?.data?.[0]?.employee_adress_proof;
    const iId_proof = employeeDetails?.data?.[0]?.employee_id_proof;
    const iState = employeeDetails?.data?.[0]?.employee_city;
    const iCountry = employeeDetails?.data?.[0]?.employee_country;
    const iAddress = employeeDetails?.data?.[0]?.employee_address;
    const iPin = employeeDetails?.data?.[0]?.employee_pin;

    setName(iName);
    setEmail(iEmail);
    setPhone(iPhone);
    setPosition(iPosition);
    setPhoto(iPhoto);
    setAdproof(iAdrs_proof);
    setIdproof(iId_proof);
    setState(iState);
    setCountry(iCountry);
    setAddress(iAddress);
    setPin(iPin);

  }, [employeeDetails]);
  
  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handlePositionChange = (event) => {
    setPosition(event.target.value);
  };
  
  const handlePhotosChange = (event) => {
    setPhoto(event.target.files[0]);
  };

  const handleIDChange = (event) => {
    setIdproof(event.target.files[0]);
  };

  const handleADChange = (event) => {
    setAdproof(event.target.files[0]);
  };

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
  };

  const handleStateChange = (event) => {
    setState(event.target.value);
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const handlePinChange = (event) => {
    setPin(event.target.value);
  };
  
  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('position', position);
    formData.append('photo', photo);
    formData.append('adr_proof', adrs_proof);
    formData.append('id_proof', id_proof);
    formData.append('address', address);
    formData.append('state', state);
    formData.append('country', country);
    formData.append('pin', pin);
    formData.append('id', employeeId);

    try {
      const response = await axios.post('https://projects.sninfoserv.com/007-api/employees/update/', formData);
      console.log(response.data);
      fetchData();
    } catch (error) {
      console.error('Error uploading data:', error);
    }

    
  };

  return (
    <div class="g-sidenav-show   bg-gray-100">
    <div class="min-height-300 bg-primary position-absolute w-100"></div>
    { userType === '1' ?(
    <SideBar />
      ) : (< EsideBar />
     )}
    <main class="main-content position-relative border-radius-lg ">
    
    <NavBar />
   
    <div class="container-fluid py-4">
    <div class="row">
        <div class="col-md-8  order-lg-1 order-2">
          <div class="card">
            <div class="card-body">
              <p class="text-uppercase text-sm">Employee Details</p>
              <form onSubmit={handleFormSubmit}>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Name</label>
                        <input class="form-control" type="text" value={name} onChange={handleNameChange} /> 
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Email address</label>
                        <input class="form-control" type="email" value={email} onChange={handleEmailChange}  /> 
                     
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Phone Number</label>
                        <input class="form-control" type="text" value={phone} onChange={handlePhoneChange} /> 
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Position</label>
                        <input class="form-control" type="text" disabled value={position} onChange={handlePositionChange} /> 
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Photo</label>
                    <input class="form-control" type="file" accept="image/jpeg,image/gif,image/png,image/webp" onChange={handlePhotosChange} />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Address Proof</label>
                    <input class="form-control" type="file" accept="image/jpeg,image/gif,image/png,image/webp,application/pdf,image/x-eps" onChange={handleADChange} />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">ID Proof</label>
                    <input class="form-control" type="file" accept="image/jpeg,image/gif,image/png,image/webp,application/pdf,image/x-eps" onChange={handleIDChange} />
                  </div>
                </div>
              </div>
              <hr class="horizontal dark"/>
              <p class="text-uppercase text-sm">Contact Information</p>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Address</label>
                        <input class="form-control" type="text" value={address} onChange={handleAddressChange} /> 
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">State</label>
                        <input class="form-control" type="text" value={state} onChange={handleStateChange} /> 
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Country</label>
                        <input class="form-control" type="text" value={country} onChange={handleCountryChange} /> 
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Postal code</label>
                        <input class="form-control" type="text" value={pin} onChange={handlePinChange} /> 
                  </div>
                </div>
                <div class="text-center">
                   <button className="btn btn-primary btn-sm mt-3 px-6" style={{marginLeft:'50px'}}>Update</button>
                   </div>
              </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-md-4  order-lg-1 order-1">
          <div class="card card-profile">
            <img src="../documents/007_board.jpg" alt="Image placeholder" class="card-img-top"/>
            <div class="row justify-content-center">
              <div class="col-4 col-lg-4 order-lg-2">
                <div class="mt-n4 mt-lg-n6 mb-4 mb-lg-0">
                  <a href="javascript:;">
                  {employeeDetails && employeeDetails.data && employeeDetails.data.length > 0 ? (
                    <img src={`https://projects.sninfoserv.com/007-api/employees/update/uploads/${employeeDetails.data[0].employee_image}`} class="rounded-circle img-fluid border border-2 border-white"/>
                     
                      ) : ( <img src="../documents/user.webp" class="rounded-circle img-fluid border border-2 border-white"/>
                    )}
                    
                  </a>
                </div>
              </div>
            </div>
           
            <div class="card-body pt-0">
              <div class="text-center mt-4">
              {employeeDetails && employeeDetails.data && employeeDetails.data.length > 0 ? (
                      <h5> {employeeDetails.data[0].employee_name} </h5>
                      ) : ( <p>Loading...</p>
                    )}
                <div class="h6 font-weight-300">
                  {employeeDetails && employeeDetails.data && employeeDetails.data.length > 0 ? (
                      <p> {employeeDetails.data[0].employee_position} </p>
                      ) : ( <p>Loading...</p>
                    )}
                </div>
                </div>
              <div class="row justify-content-center">
              <div  class="col-12 col-lg-6 mt-3">
                {fileType1 === 'pdf' ? (
                  <embed src={fileUrl1} type="application/pdf"  width="100%" />
                  ) : fileType1 === 'image' ? (
                  <img src={fileUrl1} alt="File Preview" width="100%" />
                  ) : (
                  <p>Unsupported file type</p>
                  )}
               </div>
               <div class="col-12 col-lg-6 mt-3">
                {fileType2 === 'pdf' ? (
                  <embed src={fileUrl2} type="application/pdf"  width="100%" />
                  ) : fileType2 === 'image' ? (
                  <img src={fileUrl2} alt="File Preview" width="100%" />
                  ) : (
                  <p>Unsupported file type</p>
                  )}
               </div>
               </div>
               
            </div>
          </div>
        </div>
      </div>
      
		
        
	
	  

	  
	
      <Footer />
    </div>
  </main>

  </div>
  )
}
export default App;