import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ReactSession }  from 'react-client-session';
import { useNavigate } from 'react-router-dom';
import numeral from 'numeral';
import Popup from 'reactjs-popup';
import SideBar from './sidebar';
import NavBar from './navbar';
import Footer from './footer';

 function App() {

  const userId = ReactSession.get("userId");
  const userType = ReactSession.get("userType");
  const navigate = useNavigate();
  const indexPath = '/';
  const orderPath = '/order';
  
  useEffect(() => {
    if (userId == 0 ) {
      navigate(indexPath);
    }
    if (userType == 2 ) {
      navigate(orderPath);
    }
  }, [userId, navigate]);

  const [salary, setData] = useState(true);

 
    const fetchData = async () => {
      try {
        const response = await axios.get('https://projects.sninfoserv.com/007-api/salary/');
        setData(response.data);
        console.log(response.data);
      } catch (error) {
      } 
    };

  useEffect(() => {
    fetchData();
  }, []);
  
  const [Employees, setEmployeeData] = useState(true);
  const fetchEmployeeData = async () => {
    try {
      const response = await axios.get('https://projects.sninfoserv.com/007-api/employees/');
      setEmployeeData(response.data);
      console.log(response.data);
    } catch (error) {
    } 
  };

useEffect(() => {
  fetchEmployeeData();
}, []);


const [employeeId, setEmployee] = useState('Select an Employee');
const [amountType, setType] = useState('Select payment for');
const [paidAmount, setAmount] = useState('');
useEffect(() => {
  setEmployee(employeeId);
  setType(amountType);
});
const handleEmployeeChange = (event) => {
  setEmployee(event.target.value);
};

const handleTypeChange = (event) => {
  setType(event.target.value);
};

const handleAmountChange = (event) => {
  setAmount(event.target.value);
};

const handleFormSubmit = async (event) => {
  event.preventDefault();

  const formData = {employee:employeeId, type:amountType, amount:paidAmount};
  try {
    const response = await axios.post('https://projects.sninfoserv.com/007-api/salary/update/', formData);
    console.log(response.data);
    fetchData();
  } catch (error) {
    console.error('Error uploading data:', error);
  }

};
  

  return (
    <div class="g-sidenav-show   bg-gray-100">
    <div class="min-height-300 bg-primary position-absolute w-100"></div>
    <SideBar />

    <main class="main-content position-relative border-radius-lg ">
    
    <NavBar />
   
    <div class="container-fluid py-4">
    <div class="row">
        <div class="col-12">
          <div class="card mb-4">
            <div class="card-header pb-0">
              <h6>Employees Salary
              <Popup trigger={<button class="btn btn-primary btn-sm ms-auto" style={{float:'right',}}>Pay Now</button>} >
                         <div class="col-lg-12" >
                          <div class="card h-100">
                           <div class="card-header pb-0 p-3">
                            <div class="row">
                             <div class="col-12 d-flex align-items-center">
                              <h6 class="mb-0 text-primary">Pay for Employee</h6>
                             </div>
                             
                            </div>
                           </div>
                           <div class="card-body p-3 pb-0">
                           <form onSubmit={handleFormSubmit}>
                            <div class="row">
                             <div class="col-md-12">
                              <div class="form-group">
                               <label for="example-text-input" class="form-control-label">Employee</label>
                                <select class="form-control" required  onChange={handleEmployeeChange}> 
                                <option selected hidden disabled>{employeeId}</option>
                                {Employees && Employees.data && Employees.data.map(employees => (
                                 <option value={employees.employee_id}>{employees.employee_name}</option>
                                ))}
                                </select>
                              </div>
                              <div class="form-group">
                                <select class="form-control"  required  onChange={handleTypeChange}> 
                                <option  selected hidden disabled>{amountType}</option>
                                  <option>Salary</option>
                                  <option>Advance</option>
                                </select>
                              </div>
                              <div class="form-group">
                               <input class="form-control" type="number" value={paidAmount} placeholder="Enter amount..." required onChange={handleAmountChange} />
                              </div>
                              <button className="btn btn-success w-100 btn-sm ms-auto" >
                                Update
                              </button>
                             </div>
                             </div>
                             </form>
                           </div>
                          </div>
                         </div>
                       </Popup>
              </h6>
            </div>
            <div class="card-body px-0 pt-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center justify-content-center mb-0">
                  <thead>
                 
                    <tr>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Name</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Date</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder  opacity-7">ADVANCE</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">SALARY</th>
                    </tr>
                  
                  </thead>
                  <tbody>
                  {salary && salary.data && salary.data.map(employee => (
                    <tr>
					  <td>
                        <div class="d-flex px-2">
                          <div class="my-auto">
                            <h6 class="mb-0 text-sm">{employee.employee_name}</h6>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span class="text-xs font-weight-bold">{employee.employee_date}</span>
                      </td>
                      <td>
                        <span class="text-xs font-weight-bold">₹{numeral(employee.employee_advance).format('0,0.00')}</span>
                      </td>
                      <td>
                        <span class="text-xs font-weight-bold">₹{numeral(employee.employee_salary).format('0,0.00')}</span>
                      </td>
                     
                    </tr>
                    ))}
                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
   
      <Footer />
    </div>
  </main>

  </div>
  )
  
}
export default App;