import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ReactSession }  from 'react-client-session';
import { useNavigate, useParams } from 'react-router-dom';
import SideBar from './sidebar';
import EsideBar from './esidebar';
import NavBar from './navbar';
import Footer from './footer';


 function App() {

  const userId = ReactSession.get("userId");
  const userType = ReactSession.get("userType");
  const navigate = useNavigate();
  const indexPath = '/';
  const orderPath = '/order';
  
  useEffect(() => {
    if (userId == 0 ) {
      navigate(indexPath);
    }
    if (userType == 2 ) {
      navigate(orderPath);
    }
  }, [userId, navigate]);
  

  const queryParameters = new URLSearchParams(window.location.search)
  const orderId = queryParameters.get("id")
  const value = {"order_id":orderId};

  const [orderDetails, setorderData] = useState(true);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post('https://projects.sninfoserv.com/007-api/whatsapp/order_details/', value);
        setorderData(response.data);
        console.log(response.data);
      } catch (error) {
      } 
    };

    fetchData();
  }, []);
  
  const initialvalue={order_id:orderId, status: ''};
  const [formvalue, setvalue]=useState(initialvalue);
  const [formError, setError]=useState({});
  const [formsubmit, setsubmit]=useState(false);

  const handleChange = (e) => {
    const { name, value } =e.target;
        setvalue({...formvalue, [name]: value});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setsubmit(true);

    try {
      const response = await axios.post('https://projects.sninfoserv.com/007-api/whatsapp/update/', formvalue);
      
        if(response.data.status === 'success' ){
          console.log('Status Updated!');
        }
        setError(validate(response));

      } catch (error) {
        console.error('Update failed!', error);
  
      }

    
  };

  const validate=(response)=>{
    const errors={};
      if(response.data.error){
        errors.name = response.data.error.message;
      }else{
        errors.name = 'Updated as '+ formvalue.status + '!';
      }
      return errors;
  }

  useEffect(()=>{
    // console.log(formError);
    if(Object.keys(formError).length ===0 & formsubmit){
      // console.log(formvalue);
    }
  });

  return (
    <div class="g-sidenav-show   bg-gray-100">
    <div class="min-height-300 bg-primary position-absolute w-100"></div>

    { userType === '1' ?(
    <SideBar />
      ) : (< EsideBar />
     )}

    <main class="main-content position-relative border-radius-lg ">
    
    <NavBar />
   
    <div class="container-fluid py-4">
    <div class="row mt-4">
        <div class="col-lg-7 mb-lg-0 mb-4">
          <div class="card ">
            <div class="card-header pb-0 p-3">
              <div class="d-flex justify-content-between">
                <h6 class="mb-2">Order Details</h6>
              </div>
            </div>
            <div class="card">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-8">
                  <div class="numbers">
                  {orderDetails && orderDetails.data && orderDetails.data.order.details.length > 0 ? (
                        <p class="text-sm mb-0 text-uppercase font-weight-bold"> {orderDetails.data.order.details[0].order_customer_name}</p>
                      ) : ( <p>Loading...</p>
                    )}
                    
                  </div>
                </div>
                {orderDetails && orderDetails.data && orderDetails.data.order.details.length > 0 ? (
                        <p class="text-sm mb-0 font-weight-bold mt-3"> {orderDetails.data.order.details[0].order_customer_address  }</p>
                      ) : ( <p>Loading...</p>
                    )}
                
                {orderDetails && orderDetails.data && orderDetails.data.order.details.length > 0 ? (
                        <p class="text-sm mb-0 font-weight-bold mt-3"> {orderDetails.data.order.details[0].order_customer_contact  }</p>
                      ) : ( <p>Loading...</p>
                    )}
                
                {orderDetails && orderDetails.data && orderDetails.data.order.details.length > 0 ? (
                        <p class="text-sm mb-0 font-weight-bold mt-3">Payment Mode : {orderDetails.data.order.details[0].order_payment }</p>
                      ) : ( <p>Loading...</p>
                    )}  
                
                {orderDetails && orderDetails.data && orderDetails.data.order.details.length > 0 ? (
                        <p class="text-sm mb-0 font-weight-bold mt-3">Payment Status : {orderDetails.data.order.details[0].order_payment_status  }</p>
                      ) : ( <p>Loading...</p>
                    )}
                
                {orderDetails && orderDetails.data && orderDetails.data.order.details.length > 0 ? (
                        <p class="text-sm mb-0 font-weight-bold mt-3">Order Status : {orderDetails.data.order.details[0].order_status  }</p>
                      ) : ( <p>Loading...</p>
                    )}

              </div>
              <form onSubmit={handleSubmit}>
				<div class="col-xl-12 col-lg-12 col-md-12">
				<div class="row">
                 
                  <div class="mb-3 col-xl-12 col-lg-12 col-md-6 mt-3">
				  <label>Update Order Status</label>
                    <select  class="form-control" name="status" value={formvalue.status} onChange={handleChange} aria-label="Email" aria-describedby="email-addon" required>
                    {orderDetails && orderDetails.data && orderDetails.data.order.details.length > 0 ? (
                       <option hidden > {orderDetails.data.order.details[0].order_status  }</option>
                      ) : ( <option >Loading...</option>
                    )}
                        
                        <option>Accept</option>
                        <option>Preparing</option>
                        <option>Dispatched</option>
                        <option>Delivered</option>
                        <option>Cancelled</option>
                    </select>
                  </div>
                 
                  <input type="hidden" name="id" value="1705068470" />
                 
                  <div class="text-center">
                    <button type="submit" class="btn bg-gradient-success w-100">Update</button>
                  </div>
                  <p style={{ color: 'green' }}>{formError.name}</p>
                </div>
                </div>
                </form>
            </div>
          </div>
          </div>
        </div>
        <div class="col-lg-5">
          <div class="card">
            <div class="card-header pb-0 p-3">
              <h6 class="mb-0">Items</h6>
            </div>
            <div class="card-body p-3">
            <div class="table-responsive p-0">
                <table class="table align-items-center justify-content-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Item</th>
                     <th class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2">Quantity</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>

                  {orderDetails && orderDetails.data && orderDetails.data.order.items.map(order => (

                    <tr>
					          <td>
                      <div class="d-flex px-2 py-1">
                          <div class="d-flex flex-column justify-content-center">
                            <h6 class="mb-0 text-sm">{order.product}</h6>
                          </div>
                        </div>
                      </td>
                       <td>
                        <div class="d-flex px-2">
                          <div class="my-auto">
                            <h6 class="mb-0 text-sm">{order.qty}</h6>
                          </div>
                        </div>
                      </td>
                    </tr>
                    
                    ))}
                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
   
		
        
	
	  

	  
	
      <Footer />
    </div>
  </main>

  </div>
  )
}
export default App;